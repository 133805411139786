module.exports = [
    {
        name: "Tagihan Online",
        slug: "tagihan-online",
        title: "Solusi Tagihan Online",
        description: `Fasilitas akses online yang memungkinkan para orang tua atau wali murid untuk melihat dan membayar tagihan pendidikan serta biaya kegiatan sekolah dengan mudah dan efisien.`,
        image: "/image/done.svg"
    },
    {
        name: "WA Pengingat Tagihan",
        slug: "wa-pengingat-tagihan",
        title: "Solusi WA Pengingat Tagihan",
        description: `Solusi yang memanfaatkan platform WhatsApp yang memungkinkan para orang tua menerima pemberitahuan tagihan secara langsung melalui pesan WhatsApp, memungkinkan mereka untuk dengan cepat melihat dan membayar tagihan tersebut secara online.`,
        image: "/image/done.svg"
    },
    {
        name: "Tabungan",
        slug: "tabungan",
        title: "Solusi Tabungan",
        description: `Solusi untuk mengelola tabungan siswa secara efisien, baik dalam bentuk tunai maupun online. Solusi ini memungkinkan sekolah untuk mencatat dengan akurat setiap transaksi tabungan siswa.`,
        image: "/image/done.svg"
    },
    {
        name: "Donasi",
        slug: "donasi",
        title: "Solusi Donasi",
        description: `Bertujuan untuk memfasilitasi proses pengumpulan dan pengelolaan donasi secara efisien dan transparan. Dengan solusi ini, organisasi atau sekolah dapat dengan mudah membuat kampanye donasi untuk berbagai tujuan.`,
        image: "/image/done.svg"
    },
]