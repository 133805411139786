import { FaChevronDown } from "react-icons/fa6";
import Keuangan from "@/data/Solution/Finance";
import Informasi from "@/data/Solution/Information";
import Akademik from "@/data/Solution/Academic";
import Kesiswaan from "@/data/Solution/Studentship";
import Link from "next/link";

export default function SolutionDropdown() {
  return (
    <div className="dropdown">
      <div
        tabIndex={0}
        role="button"
        className="text-dark-muted font-semibold flex items-center gap-2"
      >
        Solusi <FaChevronDown className="w-3 h-3" />
      </div>
      <ul
        tabIndex={0}
        className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-[30rem] mt-6"
      >
        <div className="flex">
          <div>
            <li>
              <h2 className="menu-title">Keuangan</h2>
              <ul>
                {Keuangan.map((x, idx) => {
                  return (
                    <li key={idx}>
                      <Link href={`/solusi/keuangan/${x.slug}`}>{x.name}</Link>
                    </li>
                  );
                })}
              </ul>
            </li>
            <li>
              <h2 className="menu-title">Informasi</h2>
              <ul>
                {Informasi.map((x, idx) => {
                  return (
                    <li key={idx}>
                      <Link href={`/solusi/informasi/${x.slug}`}>{x.name}</Link>
                    </li>
                  );
                })}
              </ul>
            </li>
            <li>
              <h2 className="menu-title">Akademik</h2>
              <ul>
                {Akademik.map((x, idx) => {
                  return (
                    <li key={idx}>
                      <Link href={`/solusi/akademik/${x.slug}`}>{x.name}</Link>
                    </li>
                  );
                })}
              </ul>
            </li>
          </div>
          <div>
            <li>
              <h2 className="menu-title">Kesiswaan</h2>
              <ul>
                {Kesiswaan.map((x, idx) => {
                  return (
                    <li key={idx}>
                      <Link href={`/solusi/kesiswaan/${x.slug}`}>{x.name}</Link>
                    </li>
                  );
                })}
              </ul>
            </li>
          </div>
        </div>
      </ul>
    </div>
  );
}
