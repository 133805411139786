module.exports = [
    {
        name: "Pengumuman",
        slug: "pengumuan",
        title: "Solusi Pengumuman",
        description: `Mmemungkinkan manajemen sekolah untuk membuat dan menyebarkan pengumuman kepada para orang tua atau wali murid. Dengan solusi ini, manajemen sekolah dapat dengan mudah membuat pengumuman tentang berbagai hal, mulai dari informasi terkait acara sekolah, perubahan jadwal, pengumuman penting, hingga prestasi siswa.`,
        image: "/image/done.svg"
    },
    {
        name: "WhatsApp Tagihan",
        slug: "whatsapp-tagihan",
        title: "Solusi WhatsApp Tagihan",
        description: `Solusi yang memanfaatkan platform WhatsApp yang memungkinkan para orang tua menerima pemberitahuan tagihan secara langsung melalui pesan WhatsApp, memungkinkan mereka untuk dengan cepat melihat dan membayar tagihan tersebut secara online.`,
        image: "/image/done.svg"
    },
]