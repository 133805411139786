'use client'

import { IoIosMenu } from "react-icons/io";
import SolutionDropdown from "./NavbarDropdown/Solution";
import PartnerDropdown from "./NavbarDropdown/Partner";
import PaymentDropdown from "./NavbarDropdown/Payment";
import AboutDropdown from "./NavbarDropdown/About";
import { usePathname } from "next/navigation";
import Link from "next/link";
import { RiCustomerService2Fill } from "react-icons/ri";

export default function Navbar() {
    let path = usePathname()
    return (
        <div className="fixed z-[999] w-full px-4 md:px-16 py-8">
            <div className="bg-[#F2F5FF]/50 backdrop-blur-md py-2 shadow rounded-full flex justify-between items-center px-8">
                <Link href="/">
                    <img src="/logo-primary.svg" alt="Cards logo" className="w-32" />
                </Link>
                <div className="lg:flex items-center gap-6 hidden text-sm">
                    <Link href="/" className={`font-semibold ${path === '/' ? 'text-white bg-primary rounded-full py-2 px-8' : 'text-dark-muted font-semibold flex items-center gap-2'}`}>Beranda</Link>
                    <PartnerDropdown />
                    <SolutionDropdown />
                    <PaymentDropdown />
                    <AboutDropdown />
                </div>
                <div className="hidden lg:block text-sm">
                    <Link href="/kontak" className="btn btn-accent text-white font-semibold rounded-xl px-8 !min-h-0 h-full py-3">
                        Kontak
                        <RiCustomerService2Fill className="w-5 h-5" />
                    </Link>
                </div>
                <div className="block lg:hidden">
                    <label htmlFor="my-drawer" className="cursor-pointer drawer-button">
                        <IoIosMenu className="w-8 h-8 fill-dark-muted" />
                    </label>
                </div>
            </div>
        </div>
    )
}