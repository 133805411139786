module.exports = [
    {
        name: "Progres Kegiatan",
        slug: "progres-kegiatan",
        title: "Solusi Progres Kegiatan",
        description: `Memungkinkan kesiswaan untuk melacak dan memantau perkembangan akademik siswa secara terperinci. Dengan solusi ini, para orang tua dapat memantau progres siswa dalam berbagai aspek akademik.`,
        image: "/image/done.svg"
    },
    {
        name: "Pelanggaran",
        slug: "pelanggaran",
        title: "Solusi Poin Pelanggaran",
        description: `Memungkinkan kesiswaan untuk mencatat dan melacak pelanggaran perilaku siswa secara efisien. Dengan solusi ini, para orangtua dapat memantau setiap pelanggaran yang dilakukan oleh siswa.`,
        image: "/image/done.svg"
    },
    {
        name: "Prestasi",
        slug: "prestasi",
        title: "Solusi Prestasi",
        description: `Memungkinkan kesiswaan untuk mengelola dan mengakses prestasi siswa secara terperinci, sementara orang tua dapat memantau perkembangan prestasi anak mereka.`,
        image: "/image/done.svg"
    },
    {
        name: "Progres",
        slug: "progres",
        title: "Solusi Progres",
        description: `Mempermudah dalam memperbarui dan mengelola progres pembelajaran, kehadiran, rapor, dan konseling siswa secara efisien.`,
        image: "/image/done.svg"
    },
]