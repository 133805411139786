module.exports = [
    {
        name: "BNI",
        slug: "bni",
        title: "Bayar Tagihan via BNI",
        description: `Di Cards anda juga dapat membayar tagihan melalui Virtual Account BNI. Dengan metode yang nyaman dan aman untuk melakukan transaksi keuangan secara online ini.`,
        logo: "/image/payment/bni.svg",
        image: "/image/payment.svg"
    },
    {
        name: "BRI",
        slug: "bri",
        title: "Bayar Tagihan via BRI",
        description: `Di Cards anda juga dapat membayar tagihan melalui Virtual Account BRI. Dengan metode yang nyaman dan aman untuk melakukan transaksi keuangan secara online ini.`,
        logo: "/image/payment/bri.svg",
        image: "/image/payment.svg"
    },
    {
        name: "Mandiri",
        slug: "mandiri",
        title: "Bayar Tagihan via Bank Mandiri",
        description: `Di Cards anda juga dapat membayar tagihan melalui Virtual Account Bank Mandiri. Dengan metode yang nyaman dan aman untuk melakukan transaksi keuangan secara online ini.`,
        logo: "/image/payment/mandiri.svg",
        image: "/image/payment.svg"
    },
    {
        name: "BSI",
        slug: "bsi",
        title: "Bayar Tagihan via BSI",
        description: `Di Cards anda juga dapat membayar tagihan melalui Virtual Account BSI. Dengan metode yang nyaman dan aman untuk melakukan transaksi keuangan secara online ini.`,
        logo: "/image/payment/bsi.png",
        image: "/image/payment.svg"
    },
    {
        name: "CIMB Niaga",
        slug: "cimb-niaga",
        title: "Bayar Tagihan via CIMB Niaga",
        description: `Di Cards anda juga dapat membayar tagihan melalui Virtual Account Cimb Niaga. Dengan metode yang nyaman dan aman untuk melakukan transaksi keuangan secara online ini.`,
        logo: "/image/payment/cimb.svg",
        image: "/image/payment.svg"
    },
    {
        name: "BNC",
        slug: "bnc",
        title: "Bayar Tagihan via BNC",
        description: `Di Cards anda juga dapat membayar tagihan melalui Virtual Account BNC. Dengan metode yang nyaman dan aman untuk melakukan transaksi keuangan secara online ini.`,
        logo: "/image/payment/bnc.png",
        image: "/image/payment.svg"
    },
    {
        name: "Permata",
        slug: "permata",
        title: "Bayar Tagihan via Bank Permata",
        description: `Di Cards anda juga dapat membayar tagihan melalui Virtual Account Bank Permata. Dengan metode yang nyaman dan aman untuk melakukan transaksi keuangan secara online ini.`,
        logo: "/image/payment/permata.svg",
        image: "/image/payment.svg"
    },
    {
        name: "BJB",
        slug: "bjb",
        title: "Bayar Tagihan via Bank BJB",
        description: `Di Cards anda juga dapat membayar tagihan melalui Virtual Account BJB. Dengan metode yang nyaman dan aman untuk melakukan transaksi keuangan secara online ini.`,
        logo: "/image/payment/bjb.svg",
        image: "/image/payment.svg"
    },
]