import Link from "next/link";
import { FaChevronDown } from "react-icons/fa6";

export default function PartnerDropdown() {
  return (
    <div className="dropdown">
      <div
        tabIndex={0}
        role="button"
        className="text-dark-muted font-semibold flex items-center gap-2"
      >
        Partner <FaChevronDown className="w-3 h-3" />
      </div>
      <ul
        tabIndex={0}
        className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-52 mt-6"
      >
        <li>
          <Link href="/partner/sekolah">Sekolah</Link>
        </li>
        <li>
          <Link href="/partner/pesantren">Pesantren</Link>
        </li>
      </ul>
    </div>
  );
}
